import { NextPage } from 'next';
import Head from 'next/head';

import { getPageTitleString } from 'helpers/page';

import { useRedirectToUserCompany } from 'hooks/auth';

import { AuthProtection } from 'components/shared/layout/auth/AuthProtection';

const LoginPage: NextPage = () => {
  useRedirectToUserCompany();

  return (
    <>
      <Head>
        <title>{getPageTitleString('Login')}</title>
      </Head>

      <AuthProtection />
    </>
  );
};

export default LoginPage;
